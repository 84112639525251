.emailCollector {
  color: #2d3748; /* dark:text-gray-100 */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.title {
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
  font-weight: 600;
}

.inputWrapper {
  position: relative;
}

.emailInput {
  color: black;
  width: 18rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: 1px solid #e2e8f0;
  outline: none;
  transition: all 0.3s ease;
}

.emailInput:focus {
  border-color: transparent;
  box-shadow: 0 0 0 2px #4a90e2; /* focus:ring-primary-600 */
}

.buttonWrapper {
  margin-top: 1.5rem;
  width: 100%;
}

.submitButton {
  width: 100%;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  background-color: rgba(41, 117, 204);
  /*background-color: rgba(248, 0, 36, 0.53);*/
  color: white;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease; /* added transform for tilt effect */
  cursor: pointer;
  outline: none;
}

.submitButton:hover {
  background-color: rgba(255, 79, 102, 0.78);
  transform: scale(1.02); /* added this for a small "pop" effect on hover */
}

.submitButton:focus {
  box-shadow: 0 0 0 2px rgba(255, 79, 102, 0.78);
}

.submitButton.disabled {
  cursor: not-allowed;
  background-color: #b5d5ea;
}

.message {
  text-align: center;
  /*width: 18rem;*/
  margin-top: 0.5rem;
  font-size: 0.875rem;
  /*color: #e53e3e; !* text-red-500 *!*/
  color: white; /* text-red-500 */
}

.emailCollector {
  color: white; /* Making the text color white */
}

.submitButton {
  width: auto; /* Adjusting the button width */
  padding: 0.5rem 2rem; /* Increased horizontal padding to make the button wider */
}
