.radiance {
  @apply absolute;
  @apply bottom-[15%];
  @apply lg:top-1/3 lg:-translate-y-1/3;
  @apply left-[15%];
  @apply h-[120px] w-1/2;
  @apply lg:h-1/3 lg:w-1/3;
  @apply rounded-full;
  @apply bg-wtf-persianRed opacity-60 md:opacity-40 lg:opacity-80;
  @apply filter blur-[120px] lg:blur-[167px];
}

/* Apply only on Mozilla Firefox */
@supports (-moz-appearance: none) {
  .radiance {
    @apply h-[160px] w-3/4;
    @apply lg:h-full lg:w-2/3;
    @apply bottom-[10%] left-0;
    @apply opacity-30 md:opacity-20 lg:opacity-10;
  }
}